import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import {
  base64ToBlob,
  fetchAddress,
  getGoogleAPIKey,
  getItem,
} from "../../service/shared.service";
import { useNavigate, useParams } from "react-router-dom";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import httpApiCall from "../../service/api.service";
import toastrSer from "../../service/toast.service";
import ContinueFooter from "../LayoutComponent/continueFooter/ContinueFooter";
import "./CustomerDetails.scss";
import { saveInfoData } from "../../Redux/slices/payment.slice";
import InputMask from "react-input-mask";
import { setLoading } from "../../Redux/slices/loader.slice";
import { formatDateOld } from "../../utils/dateformatter";

interface CustomerDetailsProps {
  disputeData?: any;
  route?: any;
  Justback?: any;
}

const CustomerDetails: FC<CustomerDetailsProps> = ({ disputeData, route, Justback }) => {
  const { patients, split_request_data, info }: any = useSelector(
    (state: RootState) => state.paymentDetails || {}
  );
  const dispatch = useDispatch<AppDispatch>();
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const toastr = new toastrSer(true);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [docId, setDocID] = useState<string>("");
  const [address, setAddress] = useState<any>();

  // const dobKeys = {
  //   month: "month",
  //   day: "day",
  //   year: "year",
  // };

  useEffect(() => {
    if (
      !split_request_data ||
      (split_request_data && !split_request_data.treatment_amount)
    ) {
      if (
        (getItem("partial_amount") && Number(getItem("partial_amount"))) ||
        getItem("create_plan")
      ) {
        navigate("/debt-recuvery/plans/" + token);
      } else {
        navigate("/debt-recuvery/" + token);
      }
    }
    if (patients && patients.date_of_birth) {
      const dob = patients.date_of_birth.toString().split("-");
      setValue("month", dob[0]);
      setValue("day", dob[1]);
      setValue("year", dob[2]);
    }
    if (patients && patients.proof_of_id) {
      setDocID(patients?.doc_id);
    }
    console.log(
      info,
      "Patientsinfoinfoinfoinfo--------------------------------"
    );

    if (info.patient_address) {
      const fields = [
        "patient_first_name",
        "patient_last_name",
        "patient_address",
        "dob",
        "patient_mobile",
        "address_line_2",
        "proof_of_id",
        "state_name",
        "zip",
        "city",
        "patient_ssn",
      ];

      fields.forEach((field) => {
        setValue(field, info[field] || "");
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients]);

  const validationSchema = Yup.object().shape({
    patient_image: Yup.string().nullable().notRequired(),
    patient_first_name: Yup.string().required("First name is required"),
    patient_last_name: Yup.string().required("Last name is required"),
    dob: Yup.string()
      .required("DOB is required")
      .test("valid-format", "Invalid date format. Use MM/DD/YYYY.", (value) => {
        if (!value) return false;
        const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
        return dateRegex.test(value); // Check the format
      })
      .test("valid-date", "Invalid date.", (value) => {
        if (!value) return false;
        const [month, day, year] = value.split("/").map(Number);

        // Check if the date is logically valid
        if (month < 1 || month > 12 || day < 1 || day > 31) return false;
        const daysInMonth = new Date(year, month, 0).getDate();
        if (day > daysInMonth) return false;

        return true;
      })
      .test("reasonable-year", "Invalid DOB", (value) => {
        if (!value) return false;
        const [_, __, year] = value.split("/").map(Number);
        const currentYear = new Date().getFullYear();
        return year >= 1900 && year <= currentYear;
      })
      .test("min-age", "You must be at least 18 years old.", (value) => {
        if (!value) return false;
        const [month, day, year] = value.split("/").map(Number);
        const birthDate = new Date(year, month - 1, day);
        const ageDiffMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDiffMs);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);
        return age >= 18;
      }),
    // month: Yup.string().required("Month is required"),
    // day: Yup.string().required("Day is required"),
    // year: Yup.string().required("Year is required"),
    patient_address: Yup.string().required("Address is required"),
    address_line_2: Yup.string().nullable().notRequired(), // Optional
    zip: Yup.string().required("ZIP is required"),
    city: Yup.string().required("City is required"),
    state_name: Yup.string().required("State is required"),
    patient_mobile: Yup.string()
      .transform((value) => value.replace(/^\+1/, "").replace(/\D/g, "")) // Remove all non-digit characters
      .required("Phone number is required")
      .length(10, "Phone number is invalid"),
    patient_ssn: Yup.string().required("SSN is required").length(9, "SSN is invalid"),
    patient_email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
  });

  console.log(patients, "patients");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors, isValid, },
  } = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      patient_first_name: patients?.patient_first_name || "",
      patient_last_name: patients?.patient_last_name || "",
      dob: formatDateOld(patients?.date_of_birth) || "",
      patient_address: patients?.patient_address || "",
      address_line_2: patients?.address_line_2 || "",
      zip: patients?.zip || "",
      city: patients?.city || "",
      state_name: (patients && patients.state) || "",
      patient_mobile: patients && patients.patient_mobile || "",
      patient_ssn: patients?.patient_ssn || "",
      patient_email: patients?.patient_email || "",
    },
  });

  const onSubmit = async (data: any) => {
    if (data.patient_mobile.startsWith("+1")) {
      data.patient_mobile = data.patient_mobile.slice(2).trim(); // Removes "+1" and any leading/trailing spaces
    }
    let docUrl: any = "";
    if (previewImage && !docId) {
      const blob = await base64ToBlob(previewImage);
      console.log(blob);
      const uploadResp = await httpApiCall({
        type: "form",
        api: "proposal/upload_proof",
        body: { patient_doc: blob, patient_id: patients?.patient_id },
      });
      if (!uploadResp.is_error) {
        if (
          uploadResp &&
          uploadResp.result &&
          uploadResp.result.length &&
          uploadResp.result[0].id
        ) {
          setDocID(uploadResp.result[0].id);
          docUrl = uploadResp.result[0].url;
        }
      }
    } else {
      docUrl = patients?.proof_of_id;
    }
    function formatDOB(date: any) {
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    const formattedDOB = formatDOB(data.dob);

    console.log(formattedDOB);
    // if (docUrl) {
    data["patient_dob"] = formattedDOB;
    data["proof_of_id"] = docUrl ? docUrl : undefined;
    data["address_line_2"] = data.address_line_2
      ? data.address_line_2
      : undefined;
    data["contract_patient_token"] = token;
    data["patient_mobile_country_code"] = "US";
    let api = "add_patient_detail";
    let disputeType = 0;
    delete data.patient_image;
    delete data.day;
    delete data.month;
    delete data.year;
    if (disputeData && disputeData.details && disputeData.details.length) {
      api = "dispute_debt";
      data["message"] = disputeData["details"];
      if (disputeData["ids"] && disputeData["ids"].length) {
        data["supported_docs"] = disputeData["ids"].join(",");
      }
      if (
        disputeData["selectedReasonIds"] &&
        disputeData["selectedReasonIds"].length
      ) {
        let reasons: any = [];
        disputeData["selectedReasonIds"].forEach((reason: any) => {
          reasons.push(reason.reason_id);
        });
        data["reason_id"] = reasons.join(",");
      }
      disputeType = 1;
    }
    dispatch(saveInfoData(data));
    dispatch(setLoading(true));
    const response = await httpApiCall({
      type: "POST",
      api: "proposal/" + api,
      body: data,
    });
    dispatch(setLoading(false));
    if (response && response.is_error && response.is_error === 1) {
      toastr.showError(response.original_text);
    } else {
      if (disputeType) {
        navigate("/thanks/" + token);
      } else {
        navigate("/debt-recuvery/pay-now/" + token + "?choosePlan=true");
      }
    }
    // }
  };

  // for address
  const getLocation = (location_data: any) => {
    const data = fetchAddress(location_data);
    setAddress(data && data.address_line_1)
    setValue("patient_address", (data && data.address_line_1) || "");
    setValue("state_name", (data && data.state_name) || "");
    setValue("zip", (data && data.zip) || "");
    setValue("city", (data && data.city) || "");
  };
  const reset = (location_data: any) => {
    if (!location_data) {
      setValue("patient_address", (""));
      setValue("state_name", (""));
      setValue("zip", (""));
      setValue("city", (""));
    }
  };

  // date of birth functionlity
  // const handleDateInput2 = (event: any, currentIndex?: number, key?: any) => {
  //   const value = event.target.value;
  //   const maxLength = currentIndex === 2 ? 4 : 2; // 4 for year, 2 for month and day
  //   const updatedValue = value.slice(0, maxLength);

  //   if (dobKeys.month === key) {
  //     // Month validation
  //     const month = Math.min(Math.max(1, updatedValue), 12); // Restrict month to 1-12
  //     //   updateValue(month.toString().padStart(2, "0")); // Update to 2-digit format
  //     setValue(key, month.toString(), { shouldValidate: true });
  //   } else if (dobKeys.day === key) {
  //     // Day validation
  //     const month = watch("month") || "01";
  //     const year = watch("year") || new Date().getFullYear();
  //     const maxDays = new Date(year, parseInt(month), 0).getDate();
  //     const day = Math.min(
  //       Math.max(1, parseInt(updatedValue || 1, 10)),
  //       maxDays
  //     );
  //     setValue(key, day.toString(), { shouldValidate: true });
  //   } else if (dobKeys.year === key) {
  //     // Year validation
  //     if (updatedValue.toString().length === maxLength) {
  //       const year = parseInt(updatedValue || 1900, 10);
  //       const currentYear = new Date().getFullYear() - 10;
  //       setValue(key, Math.min(Math.max(1900, year), currentYear).toString(), {
  //         shouldValidate: true,
  //       });
  //     }
  //   }

  //   if (updatedValue.length === maxLength) {
  //     moveToNextField(0);
  //   }
  // };
  const handleDateInput = (e: any) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = value.slice(0, 2) + "/" + value.slice(2); // Add `/` after month
    } else if (value.length > 4) {
      formattedValue =
        value.slice(0, 2) + "/" + value.slice(2, 4) + "/" + value.slice(4); // Add `/` after day
    }
    if (formattedValue.length > 10) {
      formattedValue = formattedValue.slice(0, 10);
    }

    e.target.value = formattedValue;
  };

  // const moveToNextField = (currentIndex: number) => {
  //   const nextInput = document.getElementById(`dob_${currentIndex + 1}`);
  //   if (nextInput) {
  //     nextInput.focus();
  //   }
  // };

  // on blur add 0 prior to day and month
  // const leavingInput = (event: any, key?: string) => {
  //   const value = event.target.value;
  //   if (
  //     (key === dobKeys.month || key === dobKeys.day) &&
  //     value.toString().length === 1
  //   ) {
  //     setValue(key, "0" + value.toString(), { shouldValidate: true });
  //   }
  // };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxDimension = 150; // Thumbnail size
          let width = img.width;
          let height = img.height;

          // Maintain aspect ratio
          if (width > height) {
            if (width > maxDimension) {
              height *= maxDimension / width;
              width = maxDimension;
            }
          } else {
            if (height > maxDimension) {
              width *= maxDimension / height;
              height = maxDimension;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);

          const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
          setPreviewImage(thumbnailDataUrl);

          // Update the form value with the file data
          setValue("patient_image", file);
        };
      };

      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const deleteProfile = async () => {
    if (docId) {
      const response = await httpApiCall({
        type: "POST",
        api: "proposal/delete_proof",
        body: { doc_id: docId },
      });
      if (response && response.is_error && response.is_error === 1) {
        toastr.showError(response.original_text);
      } else {
        setDocID("");
        setPreviewImage(null);
      }
    } else {
      setPreviewImage(null);
    }
  };

  const goBack = () => {
    navigate(route ? Justback() : ("/debt-recuvery/plans/" + token));
  };

  // const formatPhoneNumber = (value:any) => {
  //   // Remove non-digit characters
  //   const digits = value.replace(/\D/g, "");

  //   // Format as +1 (XXX) XXX-XXXX
  //   if (digits.length > 0) {
  //     let formatted = "+1 ";
  //     if (digits.length >= 1) formatted += `(${digits.slice(0, 3)}`;
  //     if (digits.length >= 4) formatted += `) ${digits.slice(3, 6)}`;
  //     if (digits.length >= 7) formatted += `-${digits.slice(6, 10)}`;
  //     return formatted;
  //   }
  //   return value;
  // };

  return (
    <>
      <div className="pt-4">
        <header>
          <h1 className="text-center fs-24 fw-500 pb-5"> Basic Information </h1>
        </header>
        <Form
          id="form-id"
          className="container mxw-767"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={6} className="mb-3">
              <div className={`floating-label ${errors.patient_first_name ? 'has-error' : ''}`}>
                <input
                  type="text"
                  {...register("patient_first_name")}
                  className="styled-input w-100"
                  placeholder=" "
                />
                <label>First name</label>
              </div>
              {errors?.patient_first_name?.message &&
                typeof errors.patient_first_name.message === "string" && (
                  <span className="text-danger">
                    {errors.patient_first_name.message}
                  </span>
                )}
            </Col>

            <Col md={6} className="mb-3">
              <div className={`floating-label ${errors.patient_last_name ? 'has-error' : ''}`}>
                <input
                  type="text"
                  {...register("patient_last_name")}
                  className="styled-input w-100"
                  placeholder=" "
                />
                <label>Last name</label>
              </div>
              {errors?.patient_last_name?.message &&
                typeof errors.patient_last_name.message === "string" && (
                  <span className="text-danger">
                    {errors.patient_last_name.message}
                  </span>
                )}
            </Col>

            <Col md={12} className="mb-3">
              <div className={`floating-label ${errors.patient_email ? 'has-error' : ''}`}>
                <input
                  type="text"
                  {...register("patient_email")}
                  className="styled-input w-100"
                  placeholder=" "
                />
                <label>Email address</label>
              </div>
              {errors?.patient_email?.message &&
                typeof errors.patient_email.message === "string" && (
                  <span className="text-danger">
                    {errors.patient_email.message}
                  </span>
                )}
            </Col>

            <Col md={6} className="mb-3">
              <div className={`floating-label ${errors.dob ? 'has-error' : ''}`}>
                <input
                  type="text"
                  {...register("dob")}
                  onInput={(e) => handleDateInput(e)}
                  className="styled-input w-100"
                  placeholder=" "
                />
                <label>Date of Birth (MM/DD/YYYY)</label>
              </div>
              {errors?.dob?.message &&
                typeof errors.dob.message === "string" && (
                  <span className="text-danger">{errors.dob.message}</span>
                )}
            </Col>

            <Col md={6} className="mb-3">
              <div className={`floating-label ${errors.patient_mobile ? 'has-error' : ''}`}>
                <InputMask
                  mask="+1 999-999-9999"
                  maskChar={null}
                  {...register("patient_mobile")}
                  className="styled-input w-100"
                  placeholder=" "
                  defaultValue={patients && patients.patient_mobile} // Ensure the initial value is displayed
                  onBlur={(e) => setValue("patient_mobile", e.target.value)} // Update value on blur
                />
                <label>Phone number</label>
              </div>
              {errors?.patient_mobile?.message &&
                typeof errors.patient_mobile.message === "string" && (
                  <span className="text-danger">
                    {errors.patient_mobile.message}
                  </span>
                )}
            </Col>

            <Col md={6} className="mb-3">
              <div className={`floating-label ${errors.patient_address && !isValid  && !address ? 'has-error' : ''}`}>
                <ReactGoogleAutocomplete
                  apiKey={getGoogleAPIKey()}
                  onPlaceSelected={(place) => getLocation(place)}
                  className="styled-input w-100"
                  {...register("patient_address")}
                  // onKeyUp={(place) => getLocation(place)}
                  options={{
                    types: ["geocode"],
                    componentRestrictions: { country: "us" },
                  }}
                  placeholder=" "
                  defaultValue={info?.patient_address ? info?.patient_address : patients && patients.patient_address || ''}

                />
                <label>Address</label>
              </div>
              {errors?.patient_address?.message && typeof errors.patient_address.message === "string" && !isValid && !address &&  (
                  <span className="text-danger">
                    {errors.patient_address.message}
                  </span>
                )}
            </Col>


            <Col md={6} className="mb-3">
              <div className={`floating-label ${errors.address_line_2 ? 'has-error' : ''}`}>
                <input
                  type="text"
                  {...register("address_line_2")}
                  className="styled-input w-100"
                  placeholder=" "
                />
                <label>Address line 2 (optional)</label>
              </div>
              {errors?.address_line_2?.message &&
                typeof errors.address_line_2.message === "string" && (
                  <span className="text-danger">
                    {errors.address_line_2.message}
                  </span>
                )}
            </Col>

            <Col md={6} className="mb-3">
              <div className="floating-label">
                <input
                  disabled={true}
                  type="text"
                  {...register("zip")}
                  className="styled-input w-100"
                  placeholder=" "
                />
                <label>Zip Code</label>
              </div>
            </Col>

            <Col md={6} className="mb-3">
              <div className="floating-label">
                <input
                  disabled={true}
                  type="text"
                  {...register("city")}
                  className="styled-input w-100"
                  placeholder=" "
                />
                <label>City</label>
              </div>
            </Col>

            <Col md={6} className="mb-3">
              <div className="floating-label">
                <input
                  disabled={true}
                  type="text"
                  {...register("state_name")}
                  className="styled-input w-100"
                  placeholder=" "
                />
                <label>State</label>
              </div>
            </Col>

            <Col md={6} className="mb-3">
              <div className={`floating-label ${errors.patient_ssn ? 'has-error' : ''}`}>
                <input
                  type="number"
                  {...register("patient_ssn")}
                  className="styled-input w-100"
                  placeholder=" "
                  onInput={(e: any) => {
                    const { value } = e.target;
                    e.target.value = value.replace(/\D/g, "").slice(0, 9);
                  }}
                />
                <label>Social security number (SSN)</label>
              </div>
              {errors?.patient_ssn?.message &&
                typeof errors.patient_ssn.message === "string" && (
                  <span className="" style={{ color: "red" }}>
                    {errors.patient_ssn.message}
                  </span>
                )}
            </Col>

            {!previewImage && !docId && (
              <Col md={12} className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label className="fs-14 fw-500 text-muted mb-0">
                    Proof of ID (Optional)
                  </label>
                  <span
                    className="fs-14 fw-500 text-primary cursor"
                    onClick={() => {
                      const fileInput = document.getElementById(
                        "file-upload"
                      ) as HTMLInputElement | null;
                      if (fileInput) {
                        fileInput.click();
                      }
                    }}
                  >
                    + Upload
                  </span>
                </div>
                <p className="fs-14 mxw-416 text-muted mt-1">
                  Upload a clear copy of your Driver’s License or another
                  government-issued proof of ID.
                </p>
                <Form.Group controlId="file-upload">
                  <Form.Control
                    type="file"
                    {...register("patient_image", {
                      required: "Image is required",
                    })}
                    onChange={handleImageChange}
                    isInvalid={!!errors.patient_image}
                    className="d-none"
                  />
                </Form.Group>
              </Col>
            )}

            {(previewImage || docId) && (
              <Col className="position-relative uploaded-img">
                <img
                  src={previewImage}
                  alt="Thumbnail"
                  style={{
                    border: "1px solid #ddd",
                    padding: "5px",
                    marginLeft: "12px",

                  }}
                />
                <img
                  className="deleteCross cursor"
                  src="/assets/images/cross-icon.svg"
                  onClick={deleteProfile}
                  alt=""
                />

              </Col>
            )}
          </Row>

          {/* <div className='d-flex justify-content-between'>
               <Button className='btn btn-primary p-0'><Link className='d-flex justify-content-center align-items-center h-100 w-100' style={{color: '#fff', textDecoration: 'none'}} to={'/debt-recuvery/plans/' + token}>Back</Link></Button>
               <Button variant="secondary" type="submit" className="mnw-125">
                  Save
               </Button>
            </div> */}
        </Form>
        <ContinueFooter
          back={() => goBack()}
          disable={false}
          value="Continue"
          type="submit"
          form="form-id"
        />
      </div>
    </>
  );
};

export default CustomerDetails;
