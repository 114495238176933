import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Redux/store";
import toastrSer from "../../service/toast.service";
import { setLoading } from "../../Redux/slices/loader.slice";
import httpApiCall from "../../service/api.service";


const AddPaymentMethod = (paymentSourceType: number) => {
  const dispatch = useDispatch<AppDispatch>();
  const toastr = new toastrSer(true);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [paymentParams, setPaymentParams] = useState<any>([]);
  const [contractData, setContractData] = useState<any>([]);

  useEffect(() => {
    fetchContractDetails();
  }, []);

  const fetchContractDetails = async () => {
    try {
      dispatch(setLoading(true));
      const data = { contract_id: 2 };
      const response = await httpApiCall({
        type: "GET",
        api: "proposal/get_contract_details",
        body: data,
      });
      setContractData(response?.result?.[0]);
    } catch (error) {
      toastr.showError("Error fetching contract details.");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleShow = () => {
    if (!contractData) {
      toastr.showError("Contract data is not available.");
      return;
    }
    setIsVisible(true);
    setPaymentParams({
      patient_id: contractData.patient_id,
      payment_source_type: paymentSourceType,
      payment_matrix_type: "recurring_type",
      contract_id: 2,
    });
  };

  return {
    isVisible,
    setIsVisible,
    paymentParams,
    handleShow,
    contractData
  };
};

export default AddPaymentMethod;
