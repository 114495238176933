import React, { FC } from 'react';

interface ErrorPageProps { }

const ErrorPage: FC<ErrorPageProps> = () => {
   return (
      <>
         <div>
            <h1>Oops!</h1>
            <p>Something went wrong while loading this page.</p>
         </div>
      </>)
}

export default ErrorPage;
