import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import LayoutLazyComponent from './components/LayoutComponent/LayoutComponent.lazy';
import InvalidComponent from './components/InvalidComponent/InvalidComponent.lazy';
import HomeComponent from './components/HomeComponent/HomeComponent.lazy';
import PlanCreated from './components/PlanCreated/PlanCreated.lazy';
import LinkExpire from './components/LinkExpire/LinkExpire.lazy';
import PayNowComponent from './components/PayNowComponent/PayNowComponent';
import PlansComponent from './components/PlansComponent/PlansComponent.lazy';
import AddCard from './components/AddPaymentMethod/AddCard/AddCard';
import AddBank from './components/AddPaymentMethod/AddBank/AddBank';
import ErrorPage from './components/errorpage/errorpage';
import DisputeDebt from './components/DisputeDebt/DisputeDebt.lazy';
import CustomerDetails from './components/CustomerDetails/CustomerDetails';
import SuccessPage from './components/SuccessPage/SuccessPage.lazy';
import CustomerPayment from './components/CustomerPayment/CustomerPayment.lazy';
import PaymentSuccessfull from './components/PaymentSuccessfull/PaymentSuccessfull.lazy';
import PayPartial from './components/PayPartial/PayPartial';


const RouterComponent: React.FC = () => {

  // Create the router instance
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<LayoutLazyComponent />} errorElement={<ErrorPage />}>

         {/* Default route */}
          <Route path="/" element={<InvalidComponent />} />

          {/* Invalid route */}
          <Route path="/invalid" element={<InvalidComponent />} />
           
           {/* Debt Recovery Routes */}
          <Route path="debt-recuvery">
            <Route path=":token" element={<HomeComponent />} />
            <Route path="payPartial/:token" element={<PayPartial />} />
            <Route path="pay-now/:token" element={<PayNowComponent />} />
            <Route path="plans/:token" element={<PlansComponent />} />
            <Route path="plan-created/:token" element={<PlanCreated />} />
            <Route path="customer-details/:token" element={<CustomerDetails />} />
            <Route path="dispute-debt/:token" element={<DisputeDebt />} />
            <Route path="customer-payment/:token" element={<CustomerPayment />} />
            <Route path="payment-successfull/:token" element={<PaymentSuccessfull />} />
          </Route>

          {/* Other Specific Routes */}
          <Route path="/link-expire" element={<LinkExpire />} />
          <Route path="/add-card" element={<AddCard />} />
          <Route path="/add-bank" element={<AddBank />} />
          <Route path="/thanks/:token" element={<SuccessPage />} />
        </Route>

        {/* Catch-all route for 404s */}
        <Route path="*" element={<InvalidComponent />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default RouterComponent;