import { createSlice, PayloadAction, nanoid } from '@reduxjs/toolkit';
import { DoctorCountryCurrencyInfo, FeeData, FinancingInfo, Patient, PaymentDetailsData, PlansData, SplitRequestData, StatusType, RequestProfile } from '../../types';
interface PaymentDetailsResponse extends PaymentDetailsData{
    is_error?: number,
    flag?: number,
    value?: any
}

interface PaymentDetailsState {
    doctor_country_currency_info: DoctorCountryCurrencyInfo,
    feeData: FeeData,
    financing_info: FinancingInfo | null,
    patients: Patient | null,
    split_request_data: SplitRequestData | null,
    selectedPaymentPlan: PlansData | null,
    status: StatusType;
    error?: string | null;
    flag?: number | null;
    contract_code?: string | null
    paymentsCards?: any | null
    info: any | null,
    addedCard: any,
    toPayToday: number | undefined
    request_profile: RequestProfile | null
    scanData:any,

}

// Create the slice
export const paymentDetailsSlice = createSlice({
  name: 'paymentDetails',
  initialState: {
    doctor_country_currency_info: {},
    feeData: {},
    financing_info: [],
    patients: [],
    split_request_data: {},
    selectedPaymentPlan: {},
    status: 'idle',
    error: null,
    flag: null,
    info: [],
    paymentsCards:[],
    toPayToday: 0,
    request_profile: {}
  } as unknown as PaymentDetailsState,
  reducers: {
    addPaymentDetail: {
      reducer: (state, action: PayloadAction<PaymentDetailsResponse>) => {
        action.payload = action.payload['value'];
        state.doctor_country_currency_info = action.payload.doctor_country_currency_info;
        state.feeData = action.payload.feeData;
        state.financing_info = action.payload?.financing_info && action.payload.financing_info.length ? action.payload.financing_info[0] : null;

        state.patients = action.payload?.patients && action.payload.patients.length ? action.payload.patients[0] : null;
        state.split_request_data = action.payload?.split_request_data && action.payload.split_request_data.length ? action.payload.split_request_data[0] : null;
        state.request_profile = action.payload?.request_profile && action.payload?.request_profile.length ? action.payload?.request_profile[0] : null
      },
      prepare(value: PaymentDetailsResponse): { payload: any } {
        return {
          payload: {
            key: nanoid(),
            value: value,
          },
        };
      },
    },
    setSelectedPaymentPlan: {
      reducer: (state, action: PayloadAction<PlansData>) => {
        state.selectedPaymentPlan = {...action.payload};
      },
      prepare(value: PlansData): { payload: PlansData } {
        return {
          payload: value,
        };
      },
    },
    clearSelectedPaymentPlan: (state) => {
      state.selectedPaymentPlan = null;
    },
    updatePatients: (state, action: PayloadAction<Patient[]>) => {
      state.patients = action.payload && action.payload.length ? action.payload[0] : null;
    },
    setContractCode: (state, action: PayloadAction<string>) => {
      state.contract_code = action.payload;
    },
    saveInfoData: (state, action: PayloadAction<string>) => {
      state.info = action.payload;
    },
    saveToPayToday: (state, action: PayloadAction<number | undefined>) => {
      state.toPayToday = action.payload;
    },
    setPaymentCardList: (state, action: PayloadAction<any | undefined>) => {
      state.paymentsCards = action.payload;
    },
    setExixtingCard: (state, action: PayloadAction<any | undefined>) => {
      state.addedCard = action.payload;
    },
    setScanData: (state, action: PayloadAction<any | boolean>) => {
      state.scanData = action.payload;
    }
    
  },
});

export const { addPaymentDetail, setSelectedPaymentPlan, clearSelectedPaymentPlan, updatePatients, setContractCode,saveInfoData, saveToPayToday,setPaymentCardList,setExixtingCard,setScanData} = paymentDetailsSlice.actions;
export default paymentDetailsSlice.reducer;