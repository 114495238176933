export const formatDate = (date: any) => {
  const dates = new Date(date); // Parse the date string
  const year = dates.getFullYear(); // Get the year (YYYY)
  let month = (dates.getMonth() + 1).toString().padStart(2, '0'); // Get the month (MM)
  let day = dates.getDate().toString().padStart(2, '0'); // Get the day (DD)

  return `${year}-${month}-${day}`;
}

export const formatDateNew = (date: any | Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  return new Date(date).toLocaleDateString('en-US', options);
};


export const formatDateOld = (input:any) => {
  if (!input) return "";
    const date = input instanceof Date ? input : new Date(input);

  if (isNaN(date.getTime())) return "";

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};





