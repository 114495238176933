import React, { useEffect, useState } from "react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
  AddressElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "react-bootstrap";
import { PaymentParams } from "../../types/common";
import httpApiCall from "../../service/api.service";
import { useDispatch } from "react-redux";
import { setLoading } from "../../Redux/slices/loader.slice";
import { patient_details } from "../../types";
import toastrSer from "../../service/toast.service";
// import { setPaymentCardList } from '../../Redux/slices/payment.slice';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

interface StripeModalProps {
  isActive?: boolean;
  onHide?: (param?: any) => void;
  paymentParams: PaymentParams | undefined;
  onPaymentConfirm?: (param?: any) => void;
  patient_details?: patient_details | undefined;
  default_details?: any;
  paymentElementOptions?: any;
}

// Call this wrapper whenever we need to trigger stripe for bank or card
export const Wrapper: React.FC<StripeModalProps> = ({
  isActive,
  onHide,
  paymentParams,
  patient_details,
}) => {
  const [clientSecret, setClientSecret] = useState("");
  const [options, setOptions] = useState<any>();
  const [default_details, set_default_details] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(process.env.REACT_APP_STRIPE_KEY);

    // Configure default details for AddressElement
    const details = {
      mode: "billing",
      defaultValues: {
        name: patient_details?.patient_name || "",
        email: patient_details?.patient_email || "",
        address: {
          line1: patient_details?.line1 || "",
          city: patient_details?.city || "",
          state: patient_details?.state || "",
          postal_code: patient_details?.postal_code || "",
          country: "US",
        },
      },
    };

    set_default_details(details);

    // Fetch client secret
    createClientSecret(paymentParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentParams]);
  console.log(paymentParams, "paymentParamspaymentParams");

  const createClientSecret = async (params: PaymentParams | undefined) => {
    const data = {
      type: "POST" as "POST",
      body: params,
      api: "proposal/create_payment_intent",
    };
    dispatch(setLoading(true));
    const response: any = await httpApiCall(data);
    dispatch(setLoading(false));
    setOptions({
      clientSecret: response.client_secret, // Pass the client secret to Elements
    });
    setClientSecret(response.client_secret);
  };

  const handleClose = (param?: any) => {
    if (onHide) {
      onHide(param || false);
    }
  };

  return (
    <Modal show={isActive} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="p-4">
        <Modal.Title className="fs-18 fw-700">Add payment method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <StripeModal
              paymentParams={paymentParams}
              onHide={handleClose}
              default_details={default_details}
            />
          </Elements>
        )}
      </Modal.Body>
    </Modal>
  );
};

// StripeModal component
const StripeModal: React.FC<StripeModalProps> = ({
  paymentParams,
  onHide,
  default_details,
}) => {
  const toastr = new toastrSer(true);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  console.log(default_details, "default_detailsdefault_details");

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    const element = elements.getElement(PaymentElement);
    if (!element) return;

    dispatch(setLoading(true));
    const result = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: `${window.location.href}` },
      redirect: "if_required",
    });

    if (result.error) {
      dispatch(setLoading(false));
      console.error(result.error.message);
    } else {
      addPaymentSource(result);
    }
  };

  const addPaymentSource = async (result: any) => {
    const param: any = { ...paymentParams, setup_id: result.setupIntent?.id };
    const data = {
      type: "POST" as "POST",
      body: param,
      api: "proposal/add_payment_source",
    };

    const response: any = await httpApiCall(data);
    dispatch(setLoading(false));

    if (!response.is_error && onHide) {
      onHide(response);
    } else if (response.is_error === 1 && onHide) {
      toastr.showError(response.original_text);
      onHide(response);
    }
  };

  const paymentElementOptions: any = {
    defaultValues: {
      billingDetails: {
        name:
          (default_details &&
            default_details.defaultValues &&
            default_details.defaultValues.name) ||
          "",
        email:
          (default_details &&
            default_details.defaultValues &&
            default_details.defaultValues.email) ||
          "",
      },
    },
  };

  return (
    <>
      {/* PaymentElement */}
      <PaymentElement options={paymentElementOptions} />

      {/* AddressElement with prefilled details */}
      {paymentParams && paymentParams.payment_source_type !== 1 && (
        <AddressElement options={default_details} />
      )}

      {/* Submit button */}
      <button
        onClick={handleSubmit}
        type="submit"
        disabled={!stripe}
        className="btn btn-primary mt-3"
      >
        Add
      </button>
    </>
  );
};
