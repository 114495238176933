import React, { lazy, Suspense } from 'react';

const LazyPaymentSuccessfull = lazy(() => import('./PaymentSuccessfull'));

const PaymentSuccessfull = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPaymentSuccessfull {...props} />
  </Suspense>
);

export default PaymentSuccessfull;
